import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×6\\@75% 1RM`}</p>
    <p>{`Glute Ham Raise 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time:`}</p>
    <p>{`400M Ski Erg`}</p>
    <p>{`20-Power Cleans (95/65)`}</p>
    <p>{`100-Jump Rope Singles`}</p>
    <p>{`20-Thrusters (95/65)`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday is the return of our free Partner Workout! Classes are
at 9:00 & 10:15am. If you aren’t a member but would like to attend
please email: Daniel\\@crossfittheville.org to reserve your spot.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      